<template>
  <div class="logo-wrapper" @click="backHome">
    <h1>{{ titleShow($store.user.serverName) }}</h1>
  </div>
</template>

<script>
import settings from '@/settings.js';
export default {
  name: 'Logo',
  props: {
    title: '',
  },
  methods: {
    backHome() {
      this.isLogin && this.$router.push('/dashboard');
    },
  },

  data() {
    return {};
  },

  mounted() {},
  computed: {
    isLogin() {
      return this.$router.history.current.path !== '/login';
    },
    titleShow() {
      return this.title ? this.title : settings.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #313131;
  cursor: pointer;
  margin: auto;
  img {
    height: 30px;
    margin: 0 10px 0 0;
  }
  h1 {
    font-size: 18px;
    color: #409eff;
    font-family: 'Alibaba-PuHuiTi-Medium';
  }
}
</style>
