<template>
  <div class="flight-page-topbar-wrapper">
    <Logo :title="title1" />
    <i class="el-icon-close" @click="$router.go(-1)"></i>
  </div>
</template>

<script>
import Logo from '@/components/logo';
export default {
  props: {
    title: null,
  },
  components: {
    Logo,
  },
  data() {
    return {
      title1: null,
    };
  },
  watch: {
    title(v) {
      this.title1 = v

    }
  },
  created() {
    this.title1 = this.title;
     if(this.$store.state.user.serverName){
         this.title1= this.$store.state.user.serverName
    }
  },
};
</script>

<style lang="scss" scoped>
.flight-page-topbar-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  background: #020e24;
  i {
    font-size: 24px;
    color: #fff;
  }
  i:hover {
    color: #f40;
  }
}
</style>
